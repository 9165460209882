module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-twitter-virtual-03084fdb2b/4/.yarn/berry/cache/gatsby-plugin-twitter-npm-5.13.1-33ee2ef906-10c0.zip/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-e7b7cbe4fa/4/.yarn/berry/cache/gatsby-remark-images-npm-7.13.1-bfe6d0ffe3-10c0.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":850,"quality":100,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-fb685fbdb1/4/.yarn/berry/cache/gatsby-remark-autolink-headers-npm-6.13.1-0091404466-10c0.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"maintainCase":false,"removeAccents":true,"className":"anchor"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-nprogress-virtual-c423055519/4/.yarn/berry/cache/gatsby-plugin-nprogress-npm-5.13.1-dbceab37cb-10c0.zip/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#3F80FF"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-fb00e75cd8/4/.yarn/berry/cache/gatsby-plugin-catch-links-npm-5.13.1-d0715a6524-10c0.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-0830108040/4/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Calvin Bui","short_name":"Calvin Bui","description":"Calvin Bui is a DevOps Engineer passionate about IT as a career and hobby!","start_url":"/","background_color":"#ffffff","theme_color":"#3F80FF","display":"minimal-ui","icons":[{"src":"/favicon/favicon-48.png","sizes":"48x48","type":"image/png"},{"src":"/favicon/favicon-1024.png","sizes":"1024x1024","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-d9c5fdf6b2/4/.yarn/berry/cache/gatsby-plugin-offline-npm-6.13.1-f6177c3827-10c0.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../.yarn/berry/cache/gatsby-plugin-page-progress-npm-2.2.1-c74e62c3bc-10c0.zip/node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[{"regex":"^/.*"}],"excludePaths":["/","/tags","/categories",{"regex":"^/categories/.*"},{"regex":"^/tags/.*"}],"height":3,"prependToBody":false,"color":"#cc3333","footerHeight":100,"headerHeight":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-bd8f328a09/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
